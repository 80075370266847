import { motion } from 'framer-motion';
import React from 'react'
import Button from 'UI/Button';
import Icon from 'UI/Icon';
import Product from 'types/product';

type Props = {
    pearl: Product;
    onClick: (p: Product) => void;
  }

const PearlCard = ({
    pearl,
    onClick,
  }:Props ) => {
    const { image, boost_profit_hour, title,in_inventory } = pearl;
    const revenue = in_inventory ? 100 : undefined
    return (
      <li className="boosters-list-item tapping-goods-list-item">
        <motion.img layout transition={{duration:0.2}} src={image} alt="" layoutId={image} />
        <div className="boosters-list-item-desc tapping-good-desc">
          <h4>{title}</h4>
          <div className="per-tap">
            <Icon icon="coin" />
            <p>
              +{boost_profit_hour}t<span>/h</span>
            </p>
          </div>
          <Button
          layoutId={title}
          transition={{duration:0.2}}
            className={`boosters-list-item-btn ${revenue ?? "active"}`}
            onClick={() => onClick(pearl)}
          >
            {revenue ? (
              <span className="sand-text2">{revenue} tokens</span>
            ) : (
              "Look"
            )}
          </Button>
        </div>
      </li>
    );
  };
export default PearlCard