import EarnBoosters from "./sections/EarnBoosters"
import EarnDaily from "./sections/EarnDaily"
import EarnTapping from "./sections/EarnTapping"

export type EarnSectionType = typeof EarnSections[0]

const EarnSections = [
    {
      title:'Daily',
      section:<EarnDaily/>
    },
    {
      title:'Boosters',
      section:<EarnBoosters/>
    },
    {
      title:'Tapping',
      section:<EarnTapping/>
    },
  ]
export default EarnSections