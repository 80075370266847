import React, { Dispatch, SetStateAction } from "react";
import Button from "UI/Button";
import EarnSections, { EarnSectionType } from "../earn-sections";

type Props = {
  setSection:(section:EarnSectionType) => void;
  current: EarnSectionType;
};

const EarnNavigation = ({ setSection, current }: Props) => {
  return (
    <ul className="earn-nav">
      {EarnSections.map((sec) => (
            <li key={sec.title} className={current?.title === sec.title ? `active` : ""}>
              <Button onClick={() => setSection(sec)}>{sec.title}</Button>
            </li>
          ))}
    </ul>
  );
};

export default EarnNavigation;
