import React, { useContext, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import "./Friends.css";
import gift from "assets/images/gift.png";
import Icon from "UI/Icon";
import Button from "UI/Button";
import tg from "utils/tg";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';

const Friends = () => {
  const [copy, setcopy] = useState(false)
  const {user} = useContext(appContext)
  const copyShareLink = (e:React.MouseEvent) => {
    setcopy(true)
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
    setTimeout(() => setcopy(false),1000)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
  }
  return (
    <>
    <PageWrapper>
      <UserBalance />
      <div className="friends-page-info">
        <div className={user?.friends ? 'has-friends' : ''}>
          <img className="friends-page-gift" src={gift} alt="" />
          <h1>
            <span className="sand-text2">Get 20%</span> of your <br />
            friend’s income
          </h1>
        </div>
        <div className="invite-friend">
          <div className="invite-friend-desc">
            <h3>Invite a friend</h3>
            <span>
              <Icon icon="coin" />
              +50 for you and your friend
            </span>
          </div>
          <div className="invite-friend-buttons-wrapper">
            <Button onClick={copyShareLink}>{!copy ? 'Copy link' : 'The link is copied'}</Button>
            <Button onClick={TelegramShare} sandBtn>Share</Button>
          </div>
        </div>
      </div>
      {!!user?.friends && 
        <div className="friends-list-container">
          <div className="boosters-list-wrapper">
        <div className="divider">
          <hr /> <h3>Your friends</h3>  <hr />
        </div>
        <ul className="boosters-list friends-list">
          {user.friends_list.map(({coins,name,photo}) => (
             <li className="boosters-list-item friends-list-item">
             <img src={photo} alt=""/>
             <div className="boosters-list-item-desc">
               <p>{name}</p>
               <div className="per-tap">
                <Icon icon="coin" />
                <p>
                  +{formatNumber(coins)}t
                </p>
              </div>
             </div>
           </li>
          ))}
        </ul>
      </div>
        </div>
      }
      
    </PageWrapper>
    <div className="invite-friend-counter">
        <Icon icon="navigationFriends"/>
        <p>{user?.friends} friends are invited</p>
      </div></>
  );
};

export default Friends;
