import React, { useContext } from 'react'
import './Tap.css'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'
import Icon from 'UI/Icon'
import Button from 'UI/Button'
import energyImg from 'assets/images/energy-icon.png'
import { motion } from 'framer-motion'
import Oyster from './components/Oyster'
import formatNumber from 'utils/format-number'
import appContext from 'utils/app-context'
type Props = {}

const Tap = (props: Props) => {
  const {user} = useContext(appContext)
  if(!user) return null
  return (
    <PageWrapper className='tap-page'>
      <UserBalance/>
      <div className="income">
        <div>
          <Icon icon='minCoin'/>
          <p>{formatNumber(user.profit_hour)} <span>income / hour</span></p>
        </div>
        <div>
          <Icon icon='tapIcon'/>
          <p>{formatNumber(user.coins_per_tap)} <span>income / tap</span></p>
        </div>
      </div>
      <div className="about-token-container">
      <Button className="about-token">
        <Icon icon='aboutIcon'/>
        <p>About token</p>
      </Button>
      </div>
      <Oyster/>
      <div className="energy-container">
        <div className="energy-value">
          <img src={energyImg} alt="" />
          <p><span>{formatNumber(user?.energy)}</span> / {formatNumber(user?.max_energy)}</p>
        </div>
        <div className="energy-progress-container">
          <div className="energy-progress-wrapper">
            <div className="energy-progress-line-container">
              <div className="energy-progress-line" style={{width:`${user?.energy / 10000 * user?.max_energy }%`}}/>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default Tap