import React, { useEffect, useRef, useState } from "react";
import Icon from "UI/Icon";
import PageWrapper from "UI/PageWrapper";
import './Entry.css'
import Button from "UI/Button";
import { motion, Target } from "framer-motion";
import { useNavigate } from "react-router-dom";
type Props = {};

const Entry = (props: Props) => {
  const description = useRef<HTMLDivElement>(null)
  const navigation = useNavigate()
  const measureDesc = () =>{
    if(!description.current) return
    const {top,height} = description.current.getBoundingClientRect()
    const WinHeight = window.innerHeight
    // setinitial({y:top - WinHeight - 100})
    description.current.style.transform = `translateY(-${top - WinHeight - height}px)`
  } 

  useEffect(() => {
    measureDesc()
  }, [])
  return (
    <PageWrapper className="entry-page">
      <Icon icon="logo" />
      <ul className="entry-socials">
        <li>
          <a href="#">
            <Icon icon="socialsTg" />
          </a>
        </li>
        <li>
          <a href="#">
            <Icon icon="socialsWhatsApp" />
          </a>
        </li>
        <li>
          <a href="#">
            <Icon icon="socialsInst" />
          </a>
        </li>
        <li>
          <a href="#">
            <Icon icon="socialsX" />
          </a>
        </li>
      </ul>
      <p>Complete simple tasks to play the <br /> game for free and receive tokens</p>
      <Button className="play-btn" onClick={() => navigation('/tap')}>Play!</Button>
      <div className="entry-desc-container">
        <img className="entry-img" src="./img/entry-img.png" alt="" />
        <motion.div
          // initial={initial}
          // drag={"y"}
          // ref={description}
          // dragElastic={1}
          // dragTransition={{ bounceStiffness: 100, bounceDamping: 1 }}
          // dragConstraints={constraintsRef}
          className="entry-description-block"
        >
        <div className="entry-description-content">
          <h2>Заработок</h2>
            <p>Выполняй простые задания и получай токены пеарл хаб</p>
            <hr />
            <h2>Pearl hub токен</h2>
            <p>Новая криптовалюта Порн индустрии и картинка монеты с устрицей внутри снизу под картинкой с перспективами взаимодействия токены крупными Порн сайтами (Бразерс Порн хаб Онли фанс и многие другие)</p>
        </div>
          <img src="./img/entry-desc-img.png" alt="" />
        </motion.div>
      </div>
     
    </PageWrapper>
  );
};

export default Entry;
