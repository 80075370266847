import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import "./Pearls.css";
import Icon from "UI/Icon";
import Button from "UI/Button";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { bgVariants, opacityVariants } from "utils/modal-animation";
import PearlOverview from "./components/PearlOverview";
import PearlCard from "./components/PearlCard";
import tg from "utils/tg";
import appContext from "utils/app-context";
import Product from "types/product";

const Pearls = () => {
  const [OpenedPearl, setOpenedPearl] = useState<Product | null>(
    null
  );
  const {products} = useContext(appContext)

  const goods = products?.reduce((g:{avalaible:Product[],bougth:Product[]},p) => {
    if (p.boost_profit_hour) {
      if (p.in_inventory) g.bougth.push(p)
      else g.avalaible.push(p)
    }
    return g
  },{avalaible:[],bougth:[]})
  return (
    <>
      <PageWrapper className="pearls-page">
        <UserBalance showEnergy />
        <ul className="boosters-list tapping-goods-list pearls-list">
          {goods?.avalaible.map((p) => {
            return <PearlCard pearl={p} onClick={() => setOpenedPearl(p)} />;
          })}
        </ul>
       {!!goods?.bougth.length && <div className="boosters-list-wrapper">
          <div className="divider">
            <hr /> <h3>Unlocked</h3> <hr />
          </div>
          <ul className="boosters-list tapping-goods-list pearls-list">
          {goods?.bougth.map((p) => {
            return <PearlCard pearl={p} onClick={() => setOpenedPearl(p)} />;
          })}
        </ul>
        </div>}
        
      </PageWrapper>
      <PearlOverview OpenedPearl={OpenedPearl} close={() => setOpenedPearl(null)}/>
    </>
  );
};



export default Pearls;
