import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React, { useContext, useEffect } from "react";
import Button from "UI/Button";
import Icon from "UI/Icon";
import { bgVariants, opacityVariants } from "utils/modal-animation";
import tg from "utils/tg";
import appContext from "utils/app-context";
import PearlBuyModal from "./PearlBuyModal";
import Product from "types/product";

type Props = {
  OpenedPearl: Product | null;
  close: () => void;
};

const PearlOverview = ({ OpenedPearl, close }: Props) => {
  const { setModal } = useContext(appContext);
  useEffect(() => {
    tg.BackButton.onClick(close);
    return () => {
      tg.BackButton.offClick(close);
    };
  }, []);
  return (
    <AnimatePresence>
      {OpenedPearl && (
        <motion.div
          className="modal-bg pearl-images"
          {...bgVariants}
          transition={{ duration: 0.1 }}
        >
          <motion.div {...opacityVariants} className="pearl-images-container">
            <ul className="pearl-images-list">
              <li className="pearl-image">
                <motion.img
                  layout
                  src={OpenedPearl.image}
                  layoutId={OpenedPearl.image}
                  alt=""
                />
              </li>
              <motion.li {...LiVariants} className="pearl-image">
                <img src={OpenedPearl.image} alt="" />
              </motion.li>
              <motion.li
                {...LiVariants}
                transition={{ delay: 0.5 }}
                className="pearl-image"
              >
                <img src={OpenedPearl.image} alt="" />
              </motion.li>
            </ul>
          </motion.div>
          <motion.div {...opacityVariants} className="pearl-images-buttons">
            <Button sandBtn className="back" onClick={close}>
              <Icon icon="arrowLeft" />
            </Button>
            <Button sandBtn className="more" onClick={() => setModal({content:<PearlBuyModal pearl={OpenedPearl}/>})} layoutId={OpenedPearl.title}>
              Get more
            </Button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
const LiVariants: HTMLMotionProps<"li"> = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 100, opacity: 0 },
  transition: { delay: 0.3 },
};
export default PearlOverview;
