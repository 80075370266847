import { useContext } from "react";
import Product from "types/product";
import Button from "UI/Button";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
type Props = {
    pearl: Product;
}
const PearlBuyModal = ({pearl}:Props) => {
    const {setModal} = useContext(appContext)
    return (
      <>
        <h2 style={{textAlign:'center',fontWeight:500}}>
        Buy Pearl <br />
       <span className="sand-text2">«{pearl.title}»?</span>
        </h2>
        <p style={{textAlign:'center',margin:'5px 0 20px'}}>
        It will cost you <span className="sand-text2">
          {formatNumber(pearl.price)} tokens</span> and will bring {pearl.boost_profit_hour} token{pearl.boost_profit_hour > 1 ? 's' : ''} every hour
        </p>
        <Button
            sandBtn
            className="daily-reward-gain"
            onClick={() => setModal(null)}
          >
            Buy
          </Button>
      </>
    );
  }
  
  export default PearlBuyModal