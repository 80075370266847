import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  useEffect,
  useState,
} from "react";
import formatTime from "utils/format-time";

interface Props
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  time: number;
}

const TimerText = (props: Props) => {
  const [currentTime, setCurrentTime] = useState(props.time);
  useEffect(() => {
    const interval = setInterval(() => setCurrentTime((prev) => prev-1), 1000);
    return () => clearInterval(interval);
  }, []);
  return <p {...props}>{formatTime(currentTime)}</p>;
};

export default TimerText;
