import { SendTransactionRequest, TonConnectUI, useTonConnectUI } from "@tonconnect/ui-react";
import buyProduct from "api/buy-product";
import createTransaction from "api/create-transaction";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import Product from "types/product";
import ProductCurrency from "types/product-currency";
import Button from "UI/Button";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import tg from "utils/tg";

type Props = {
  product: Product;
};

const EarnTappingBoosterCard = ({ product }: Props) => {
  const { setModal } = useContext(appContext);
  const [tonConnectUI] = useTonConnectUI();
  const { boost_tap, image, price, price_ton, title, price_friends,in_inventory } = product;
  return (
    <motion.li
      key={product.id}
      layout
      layoutId={String(product.id)}
      className="boosters-list-item tapping-goods-list-item"
      onClick={() => {
        if(in_inventory) return
        setModal({ content: <BoostModal good={product} TonConnectUI={tonConnectUI} />, close: true })
      }}
    >
      <img src={image} alt="" />
      <div className="boosters-list-item-desc tapping-good-desc">
        <h4>{title}</h4>
        <div className="per-tap">
          <Icon icon="coin" />
          <p>
            +{boost_tap}t<span>/tap</span>
          </p>
        </div>
        <p className="tapping-good-cost-text" style={{opacity:Number(!in_inventory)}}>
          This boost will cost you one of —
        </p>
        <ul className="cost-variants" style={{opacity:Number(!in_inventory)}}>
          {!!price && (
            <li>
              <Icon icon="coin" />
              <span>{price / 1000}k</span>
            </li>
          )}
          {!!price_friends && (
            <li>
              <Icon icon="person" />
              <span>{price_friends}</span>
            </li>
          )}
          {!!price_ton && (
            <li>
              <Icon icon="ton" />
              <span>{price_ton}</span>
            </li>
          )}
        </ul>
      </div>
    </motion.li>
  );
};



type BoostModalProps = { good: Product; TonConnectUI: TonConnectUI };
const BoostModal = ({
  good: { title, price_ton, price, price_friends ,id},
  TonConnectUI,
}: BoostModalProps) => {
  const [currency, setcurrency] = useState<ProductCurrency>("price_ton");
  const [waitingForConnect, setwaitingForConnect] = useState(false)
  const [loading, setloading] = useState(false)
  const [success, setsuccess] = useState(false)
  const { user ,setModal,updateProducts,updateUserInfo} = useContext(appContext);
  let unsubscribe = useRef<() => void>(null).current

  

  const handleBuyProduct = async () => {
    setloading(true)
    try {
      if (currency === "price_ton") {
        if (!TonConnectUI.connected) {
          TonConnectUI.openModal();
          setwaitingForConnect(true)
          return
        } else {
          const result = await createTransaction(id)
          if(!result) throw new Error('Error during the creation of transaction')
          const transaction:SendTransactionRequest = {
            messages: [
                {
                    address: result.address,
                    amount: String(result.amount * 1000000000),
                }
            ],
              validUntil:Math.floor(Date.now() / 1000) + result.seconds,
            }
            console.log("🚀 ~ handleTonTransaction ~ transaction:", transaction)
            const TransactionRes = await TonConnectUI.sendTransaction(transaction)
        }
      }
      const result = await buyProduct(id,currency)
      console.log("🚀 ~ handleBuyProduct ~ result:", result)
      if(result?.result) {
        setsuccess(true)
        tg.HapticFeedback.notificationOccurred('success')
        setTimeout(() => {
          setModal(null)
          updateUserInfo()
          setTimeout(updateProducts,400)
        }, 500);
      } else throw new Error('Something went wrong')
    } catch (error) {
      console.log("🚀 ~ handleBuyProduct ~ error:", error)
      tg.showAlert(`Something went wrong:\n${error}`)
    }
    setloading(false)
  };
 
  useEffect(() => {
    const checkFromBuyProduct = () => {
      console.log("🚀 ~ checkFromBuyProduct ~ TonConnectUI.connected && waitingForConnect:", TonConnectUI.connected , waitingForConnect)
      if(TonConnectUI.connected && waitingForConnect) {
        setwaitingForConnect(false)
        handleBuyProduct()
      }
    }

    if(unsubscribe) unsubscribe()
    unsubscribe = TonConnectUI.onStatusChange(checkFromBuyProduct)
    return () => {
      if(unsubscribe) unsubscribe()
    }
  }, [waitingForConnect])
  if (!user) return null;

  const avalaibleForFriends = user?.friends >= price_friends;
  const avalaibleForCoins = user?.balance >= price;
  return (
    <>
      <h2 style={{ textAlign: "center", fontWeight: 500 }}>
        Tapping boost <br />
        <span className="sand-text2">«{title}»</span>
      </h2>
      <p style={{ textAlign: "center", margin: "5px 0 20px" }}>
        If you want to buy this tapping boost, you can do it in three ways.
        Choose yours!
      </p>
      <ul className="buy-boost-buttons">
        {!!price_ton && (
          <li>
            <Button
              className={`buy-boost-select-currency tg-currency ${
                currency === "price_ton" && "active"
              }`}
              onClick={() => setcurrency("price_ton")}
              style={{}}
            >
              <Icon icon="ton" /> {price_ton} TON's
            </Button>
          </li>
        )}
        {!!price_friends && (
          <li>
            <Button
              className={`buy-boost-select-currency fr-currency ${
                currency === "price_friends" && "active"
              }`}
              errorIf={!avalaibleForFriends}
              onClick={() => {
                if (avalaibleForFriends) setcurrency("price_friends");
                else tg.HapticFeedback.notificationOccurred("error");
              }}
            >
              <Icon icon="person" />{" "}
              {avalaibleForFriends ? "" : `${user.friends} /`} {price_friends}{" "}
              invited friends
            </Button>
          </li>
        )}
        {!!price && (
          <li>
            <Button
              className={`buy-boost-select-currency cn-currency ${
                currency === "price" && "active"
              }`}
              errorIf={!avalaibleForCoins}
              onClick={() => {
                if (avalaibleForCoins) setcurrency("price");
                else tg.HapticFeedback.notificationOccurred("error");
              }}
            >
              <Icon icon="coin" />{" "}
              {avalaibleForCoins ? "" : `${user.balance} /`}{" "}
              {formatNumber(price)} tokens
            </Button>
          </li>
        )}
      </ul>
      <Button sandBtn loading={loading} className="buy-boost-btn" onClick={handleBuyProduct}>
        {success ? 'Success!' : 'Continue'}
      </Button>
      <div className="not-available-info">
        {!avalaibleForFriends && <p>You haven’t enough invited friends</p>}
        {!avalaibleForCoins && <p>You haven’t enough tokens balance</p>}
      </div>
    </>
  );
};
export default EarnTappingBoosterCard;
