import React, { useContext } from 'react'
import Button from 'UI/Button';
import appContext from 'utils/app-context';

type Props = {
  disconnect:() => void
}

const DisconnectWalletConfirm = ({disconnect}: Props) => {
  const { setModal } = useContext(appContext);

  return (
    <>
    <h2 style={{textAlign:'center',fontWeight:500}}>
   Disconnect <br /> the wallet
    </h2>
    <p style={{textAlign:'center',margin:'5px 0 20px'}}>
        Are you sure you want to disable your wallet?
    </p>
    <div style={{width:'100%',display:'flex',gap:10}}>
    <Button
        sandBtn
        className="daily-reward-gain"
        onClick={() => setModal(null)}
      >
        No
      </Button>
      <Button
        className="daily-reward-gain disconnect-btn"
        onClick={() => {
          disconnect()
          setModal(null)
        }
        }
      >
        Yes
      </Button>
    </div>
  </>
  )
}

export default DisconnectWalletConfirm