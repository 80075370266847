import React, { useContext } from "react";
import EarnSection from "../components/EarnSection";
import Icon from "UI/Icon";
import UserBalance from "UI/UserBalance";
import Button from "UI/Button";
import appContext from "utils/app-context";

type Props = {};

const EarnDaily = (props: Props) => {
  const { setModal } = useContext(appContext);
  return (
    <div>
      <UserBalance />
      <h1>Come in every day and collect the bonus!</h1>
      <ul className="daily-reward-list">
        {Array.from({ length: 24 }).map((a, i) => (
          <li
            className={`daily-reward-item ${i === 2 ? "current" : ""}`}
            key={i}
          >
            <div className="daily-reward-item-wrapper">
              <span>Day {i + 1}</span>
              <Icon icon="coin" />
              <span className="reward-value">+100</span>
            </div>
          </li>
        ))}
      </ul>
      <Button
        sandBtn
        className="daily-reward-gain"
        onClick={() => setModal({ close: false, content: <GetDailyModal /> })}
      >
        Gain
      </Button>
    </div>
  );
};

const GetDailyModal = () => {
  const { setModal } = useContext(appContext);

  return (
    <>
      <h2 style={{textAlign:'center',fontWeight:500}}>
        You get <br />
        your daily prize!
      </h2>
      <p style={{textAlign:'center',margin:'5px 0 20px'}}>
        <span className="sand-text">+100 tokens</span> on your balance
      </p>
      <Button
          sandBtn
          className="daily-reward-gain"
          onClick={() => setModal(null)}
        >
          Continue
        </Button>
    </>
  );
}

export default EarnDaily;
