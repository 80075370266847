import { useTonConnectUI, useTonAddress, THEME, WalletInfoWithOpenMethod } from "@tonconnect/ui-react";
import React, { useContext, useEffect } from "react";
import Button from "UI/Button";
import Icon from "UI/Icon";
import DisconnectWalletConfirm from "./DisconnectWalletConfirm";
import appContext from "utils/app-context";
import { log } from "node:console";

type Props = {};

const TonConnectButton = (props: Props) => {
  const { setModal } = useContext(appContext);
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const userFriendlyAddress = useTonAddress();
  const isWalletConnected = tonConnectUI.connected;
  const connectWallet = () => {
   try {
    if (isWalletConnected) {
      const d = () => tonConnectUI.disconnect();
      setModal({
        content: <DisconnectWalletConfirm disconnect={d} />,
        close: true,
      });
    } else tonConnectUI.openModal();
   } catch (error) {
    console.log("🚀 ~ connectWal ~ error:", error)
    
   }
  };

  useEffect(() => {
    setOptions({
      uiPreferences: {
        theme: THEME.DARK,
      },
    });
  }, []);
  return (
    <Button className="ton-connect-button" onClick={connectWallet}>
      {isWalletConnected ? <img src={(tonConnectUI.wallet as WalletInfoWithOpenMethod)?.imageUrl || ''} alt="" /> : <Icon icon="ton" />}
      <p>Your wallet:</p>
      <span style={{ color: isWalletConnected ? "#0098ea" : "" }}>
        {isWalletConnected
          ? collapseAddress(userFriendlyAddress)
          : "Disconnected"}
      </span>
    </Button>
  );
};
const collapseAddress = (address:string) => `${address.slice(0,2)}...${address.slice(-4)}` 

export default TonConnectButton;
